import React, { useState, useEffect } from "react";
import fire from "../config/fire";
import { Button, Row, Form, Col, Table } from "react-bootstrap";
import { MDBDataTableV5 } from "mdbreact";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Admin = (props) => {
  const formulario = {
    nombre: "",
    apellido: "",
    usuario: "",
    password: "",
    password1: "",
  };

  const [contact, setContact] = useState(formulario);
  const [alert, setAlert] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [tasks, setTasks] = useState([]);
  const [date, setdate] = useState("2021-02-02");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const db = fire.firestore();

    db.collection("form").onSnapshot((data) => {
      setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });
  };

  const showAlert = (type, message) => {
    setAlert(true);
    setAlertData({ type, message });
    setTimeout(() => {
      setAlert(false);
    }, 3000);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const db = fire.firestore();
    if (
      contact.nombre &&
      contact.apellido &&
      contact.usuario &&
      contact.password
    ) {
      if (contact.password === contact.password1) {
        fire
          .auth()
          .createUserWithEmailAndPassword(contact.usuario, contact.password)
          .then(() => {
            db.collection("user")
              .add({
                nombre: contact.nombre,
                apellido: contact.apellido,
                usuario: contact.usuario,
                password: contact.password,
              })
              .then(() => {
                showAlert("success", "Your message was sent successfull");
                setContact(formulario);
              })
              .catch((err) => {
                showAlert("danger", "Error: " + err.toString());
              });
          })
          .catch((err) => {
            showAlert("danger", "Error: " + err.toString());
          });
      } else {
        showAlert("danger", "contrasena incorrecta");
      }
    } else {
      // En caso de no llenar los elementos necesario despliega un mensaje de alerta
      showAlert("warning", "Please fill the form");
    }
  };

  const onDelete = (id) => {
    if (window.confirm("SEGURO QUE QUIERES ELIMINAR ")) {
      const db = fire.firestore();
      db.collection("form")
        .doc(id)
        .delete()
        .then(() => {
          fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const singout = () => {
    fire.auth().signOut();
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("password");
    sessionStorage.removeItem("roller");
    props.AuthListener();
  };

  const sechEmail = () => {
    console.log(date);
    const db = fire.firestore();
    db.collection("form")
      .where("fecha", "==", date)
      .onSnapshot(function (data) {
        console.log(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      });

    /*  db.collection("form").onSnapshot((data) => {
        setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }); */
  };

  const _RENDER = () => {
    const badgesData = {
      columns: [
        {
          label: "nombre",
          field: "badge",
          width: 450,
        },
        {
          label: "EMAIL",
          field: "categoria",
          width: 50,
        },
        {
          label: "NOMBRE",
          field: "activo",
          width: 50,
        },
      ],
      rows: [
        ...tasks.map((item, index) => ({
          badge: (
            <div key={item.id}>
              <Table id={item.id} striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>EMAIL</th>
                    <th>NOMBRE</th>
                    <th>DEPARTAMENTO</th>
                    <th>FECHA</th>
                    <th>NUMERO</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{item.email}</td>
                    <td>{item.name}</td>
                    <td>{item.departamento}</td>
                    <td>{item.fecha}</td>
                    <td>000{index}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>CANTIDAD</th>
                    <th>CONCEPTO DEL GASTO</th>
                    <th>SUBTOTAL</th>
                    <th>ITBIS</th>
                    <th>TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  {item.formulario.map((item, index) => (
                    <tr key={index}>
                      <td>{item.CTA}</td>
                      <td>{item.CTA}</td>
                      <td>{item.SUBTOTAL}</td>
                      <td>{item.ITBIS}</td>
                      <td>{item.TOTAL}</td>
                    </tr>
                  ))}
                </tbody>

                <thead>
                  <tr>
                    <th> </th>
                    <th></th>
                    <th>TOTAL DE GASTO</th>
                    <th>AVANCE EMITIDO</th>
                    <th>TOTAL PENDIENTE </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      {item.formulario.forEach(function (numero) {
                        sumas += parseInt(numero.TOTAL, 10);
                      })}
                      {sumas}
                    </td>
                    <td>{item.avance}</td>
                    <td>{sumas - item.avance}</td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <th>
                      JUSTIFICACION Y OBSERVACIONES DEL (OJO) GASTO (S)
                      EFECTUADOS:{" "}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{item.comentario}</td>
                  </tr>
                </tbody>
              </Table>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn btn-default"
                table={item.id}
                filename={item.email}
                sheet="tablexls"
                buttonText="DESCARGAR XLS"
              />

              <Button
                className="btn btn-primary"
                onClick={() => onDelete(item.id)}
                variant="danger"
              >
                ELIMINAR
              </Button>
            </div>
          ),
          categoria: <p> {item.email}</p>,
          activo: <div>{item.name}</div>,
        })),
      ],
    };

    return (
      <MDBDataTableV5
        hover
        entriesOptions={[5, 7]}
        entries={5}
        pagesAmount={undefined}
        data={badgesData}
        sortRows={["badge"]}
        searching={true}
        pagingTop
        searchTop
        searchBottom={false}
        scrollY
        maxHeight="100vh"
      />
    );
  };

  let sumas = 0;

  return (
    <div>
      <div>
        <nav className="navbar navbar-light">
          <p className="navbar-brand">ADMINISTRADOR DE GASTOS</p>
          <Button
            className="btn btn-default"
            variant="primary"
            onClick={singout}
          >
            LOG OUT{" "}
          </Button>
        </nav>
      </div>

      <Row>
        <Col>
          <div className="col text-center">
            <h2>ADMINISTRAR USUARIOS</h2>
            {alert && (
              <div className={`alert alert-${alertData.type}`} role="alert">
                {alertData.message}
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>NOMBRE</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={contact.nombre}
                    name="nombre"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="CANTIDAD"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>APELLIDO</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={contact.apellido}
                    name="apellido"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="apellido"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>USUARIO</Form.Label>
                  <Form.Control
                    value={contact.usuario}
                    required
                    type="text"
                    name="usuario"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="usuario"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>CREAR CONTRASEÑA</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="password"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="CONTRASEÑA"
                    value={contact.password}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>REPITA CONTRASEÑA</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="password1"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="CONTRASEÑA"
                    value={contact.password1}
                  />
                </Form.Group>
              </Form.Row>

              <Button className="btn btn-primary" type="submit">
                Agregar
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <br />

      <Row>
        <Col>
          <Form.Control
            value={date}
            min="2021-01-01"
            max="2022-12-31"
            type="date"
            onChange={(event) => {
              setdate(event.target.value);
            }}
            className="form-control"
          />
          <Button onClick={() => sechEmail()} className="btn">
            buscar
          </Button>
        </Col>
      </Row>
      <div>{_RENDER()}</div>
    </div>
  );
};

export default Admin;
