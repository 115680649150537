import React, { Component } from "react";
import Login from "../components/Login";
import Bloger from "../components/Bloger";
import Viaje from "../components/Viaje";
import Admin from "../components/Admin";
import AdminViaje from "../components/AdminViaje";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      email: "",
    };

    this.authListener = this.authListener.bind(this);
    this.getloging = this.getloging.bind(this);
  }

  componentDidMount() {
    this.authListener();
  }

  authListener() {
    if (sessionStorage.getItem("email") && sessionStorage.getItem("password")) {
      this.setState({
        user: sessionStorage.getItem("roller"),
        email: sessionStorage.getItem("email"),
      });
    } else {
      this.setState({ user: null });
    }
  }

  getloging(email, password, roller) {
    sessionStorage.setItem("email", email);
    sessionStorage.setItem("password", password);
    sessionStorage.setItem("roller", roller);
    this.authListener();
  }

  _Select = () => {
    return this.state.user === "1" ? (
      <Bloger email={this.state.email} AuthListener={this.authListener} />
    ) : (
      <Viaje email={this.state.email} AuthListener={this.authListener} />
    );
  };

  _AMDIN = () => {
    return this.state.user === "1" ? (
      <Admin AuthListener={this.authListener} />
    ) : (
      <AdminViaje AuthListener={this.authListener} />
    );
  };

  _direct = () => {
    return this.state.email === "jmiller@mecomdominicana.com"
      ? this._AMDIN()
      : this._Select();
  };
  render() {
    return (
      <div className="container">
        {this.state.user ? (
          <div>{this._direct()}</div>
        ) : (
          <Login GEtLOGIN={this.getloging} />
        )}
      </div>
    );
  }
}

export default Auth;
