import React, { useState } from "react";
import "./Bloger.css";
import fire from "../config/fire";
import { Button, Row, Form, Col, FormControl } from "react-bootstrap";
/* import FileUpload from "./FileUpload"; */
import { Table } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Bloger = (props) => {
  const frmContact = {
    CTA: "",
    GASTO: "",
    SUBTOTAL: "",
    ITBIS: "",
    TOTAL: "",
  };

  const NAME = {
    nombre: "",
    departamento: "",
    fecha: "2021-02-02",
    comentario: "",
  };
  const [value, setValue] = useState(0);
  const [nameformulario, setnameformulario] = useState(NAME);
  const [contact, setContact] = useState(frmContact);
  const [others, setothers] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleChangename = (e) => {
    const { name, value } = e.target;
    setnameformulario({ ...nameformulario, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setContact(frmContact);

    var resultado =
      parseInt(contact.CTA, 10) * parseInt(contact.SUBTOTAL, 10) +
      parseInt(contact.ITBIS, 10);
    setothers([
      ...others,
      {
        CTA: contact.CTA,
        GASTO: contact.GASTO,
        SUBTOTAL: contact.SUBTOTAL,
        ITBIS: contact.ITBIS,
        TOTAL: resultado,
      },
    ]);
  };

  const singout = () => {
    fire.auth().signOut();
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("password");
    sessionStorage.removeItem("roller");
    props.AuthListener();
  };

  const deleteTodo = (indexx) => {
    const newTodos = others.filter((_, index) => index !== indexx);
    setothers(newTodos);
  };

  const SAVEALLPDF = () => {
    var sumass = 0;

    others.forEach((element) => {
      sumass += parseInt(element.TOTAL, 10);
    });
    const doc = new jsPDF();

    doc.autoTable({
      head: [["NOMBRE", "DEPARTAMENTO", "FECHA"]],
      body: [
        [
          nameformulario.nombre,
          nameformulario.departamento,
          nameformulario.fecha,
        ],
      ],
    });
    doc.autoTable({
      head: [["CANTIDAD", "CONCEPTO DEL GASTO", "SUBTOTAL", "ITBIS", "TOTAL"]],
      body: others.map((item) => [
        [item.CTA],
        [item.GASTO],
        [item.SUBTOTAL],
        [item.ITBIS],
        [item.TOTAL],
      ]),
    });

    doc.autoTable({
      head: [["TOTAL DE GASTO", "AVANCE EMITIDO", "TOTAL PENDIENTE"]],
      body: [[sumass, value, sumass - value]],
    });

    doc.autoTable({
      head: [
        ["JUSTIFICACION Y OBSERVACIONES DEL (O LOS ) GASTO (S) EFECTUADOS:"],
      ],
      body: [[nameformulario.comentario]],
    });

    if (others.length > 0) {
      doc.save(`${props.email}.pdf`);
    } else {
      window.alert("agregar datos");
    }
  };

  const SAVEALL = () => {
    const db = fire.firestore();
    if (others.length > 0) {
      db.collection("form")
        .add({
          name: nameformulario.nombre,
          departamento: nameformulario.departamento,
          fecha: nameformulario.fecha,
          comentario: nameformulario.comentario,
          formulario: others,
          avance: value,
          email: props.email,
        })
        .then(() => {
          alert("Datos guardado");
          setothers([]);
          setContact(FormControl);
          setnameformulario(NAME);
          setValue(0);
        });
    } else {
      alert("completar todo");
    }
  };
  let sumas = 0;

  return (
    <div>
      <div>
        <nav className="navbar navbar-light ">
          <p className="navbar-brand">MECOMDOMINICANA</p>
          <Button
            className="btn btn-primary"
            variant="primary"
            onClick={singout}
          >
            LOG OUT{" "}
          </Button>
        </nav>
      </div>

      <Row>
        <Col>
          <div className="col text-center">
            <h2>RELACION DE GASTOS </h2>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>NOMBRE</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={nameformulario.nombre}
                    name="nombre"
                    onChange={handleChangename}
                    className="form-control"
                    placeholder="NOMBRE"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>DEPARTAMENTO</Form.Label>
                  <Form.Control
                    as="select"
                    value={nameformulario.departamento}
                    name="departamento"
                    onChange={handleChangename}
                    className="form-control"
                    required
                  >
                    <option value="">SELECIONAR</option>
                    <option value="VENTAS">VENTAS</option>
                    <option value="CONTABILIDAD">CONTABILIDAD</option>
                    <option value="ALMACEN">ALMACEN</option>
                    <option value="COMPRAS">COMPRAS</option>
                    <option value="GERENCIA">GERENCIA</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>FECHA</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    value={nameformulario.fecha}
                    name="fecha"
                    onChange={handleChangename}
                    className="form-control"
                    min="2019-08-08"
                    max="2022-12-31"
                  />
                </Form.Group>
              </Form.Row>
              <hr
                style={{
                  height: "1px",
                  backgroundColor: "black",
                }}
              />
              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>CANTIDAD</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    value={contact.CTA}
                    name="CTA"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="CANTIDAD"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>CONCEPTO DEL GASTO</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={contact.GASTO}
                    name="GASTO"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="GASTO"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>SUBTOTAL</Form.Label>
                  <Form.Control
                    value={contact.SUBTOTAL}
                    required
                    type="number"
                    name="SUBTOTAL"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="SUBTOTAL"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>ITBIS</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    name="ITBIS"
                    onChange={handleChange}
                    className="form-control"
                    placeholder="ITBIS"
                    value={contact.ITBIS}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>AVANCE EMITIDO</Form.Label>
                  <Form.Control
                    variant="outlined"
                    type="number"
                    required
                    name="ITBIS"
                    onChange={(event) => {
                      setValue(event.target.value);
                    }}
                    className="form-control"
                    placeholder="AVANCE EMITIDO"
                    value={value}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>COMENTARIO/JUSTIFICACIÓN</Form.Label>
                  <textarea
                    required
                    type="textarea"
                    rows="4"
                    cols="50"
                    value={nameformulario.comentario}
                    name="comentario"
                    onChange={handleChangename}
                    className="form-control"
                    placeholder="COMENTARIO"
                  />
                </Form.Group>
              </Form.Row>

              <Button className="btn btn-primary" type="submit">
                Agregar
              </Button>
            </Form>
          </div>
        </Col>
      </Row>
      <br />

      <Table id="table-to-xls" striped bordered hover variant="dark">
        <thead>
          <tr>
            <th></th>
            <th>NOMBRE</th>
            <th>DEPARTAMENTO</th>
            <th>FECHA</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td></td>
            <td>{nameformulario.nombre}</td>
            <td>{nameformulario.departamento}</td>
            <td>{nameformulario.fecha}</td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>CANTIDAD</th>
            <th>CONCEPTO DEL GASTO</th>
            <th>SUBTOTAL</th>
            <th>ITBIS</th>
            <th>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {others.map((item, index) => (
            <tr key={index}>
              <td>{item.CTA}</td>
              <td>{item.GASTO}</td>
              <td>{item.SUBTOTAL}</td>
              <td>{item.ITBIS}</td>
              <td>{item.TOTAL}</td>

              <td>
                <input
                  className="btn btn-default"
                  type="button"
                  value="DELETE"
                  variant="danger"
                  onClick={() => {
                    deleteTodo(index);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>

        <thead>
          <tr>
            <th> </th>
            <th></th>
            <th>TOTAL DE GASTO</th>
            <th>AVANCE EMITIDO</th>
            <th>TOTAL PENDIENTE </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td>
              {others.forEach(function (numero) {
                sumas += parseInt(numero.TOTAL, 10);
              })}
              {sumas}
            </td>
            <td>{value}</td>
            <td>{sumas - value}</td>
          </tr>
        </tbody>

        <thead>
          <tr>
            <th>
              JUSTIFICACION Y OBSERVACIONES DEL (O LOS ) GASTO (S) EFECTUADOS:{" "}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{nameformulario.comentario}</td>
          </tr>
        </tbody>
      </Table>

      <Button
        variant="primary"
        className="btn btn-default"
        onClick={() => SAVEALLPDF()}
      >
        Descarga PDF
      </Button>

      <Button
        style={{
          marginLeft: 15,
        }}
        className="btn btn-primary"
        onClick={() => {
          SAVEALL();
        }}
      >
        GUARDAR Y NUEVO
      </Button>
    </div>
  );
};

export default Bloger;
