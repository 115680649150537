import React, { Component } from "react";
import "./Login.css";
import fire from "../config/fire";
class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      roller: "1",
      todos: [],
      mensajes: "",
      aler: " ",
    };

    this.update = this.update.bind(this);

    this.displayLogin = this.displayLogin.bind(this);
  }

  update(e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({
      [name]: value,
    });
  }
  displayLogin(e) {
    e.preventDefault();
    fire
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((u) => {
        this.props.GEtLOGIN(
          this.state.email,
          this.state.password,
          this.state.roller
        );
      })
      .catch((err) => {
        this.setState({
          mensajes: "Error: " + err.toString(),
          aler: "alert-danger",
        });
      });
  }

  render() {
    return (
      <div className="login">
        <div className={"alert " + this.state.aler}>{this.state.mensajes}</div>

        <form onSubmit={this.displayLogin}>
          <h2 className="center">Login</h2>

          <div className="form-group">
            <input
              type="text"
              placeholder="Correo"
              className="form-control"
              value={this.state.email}
              onChange={this.update}
              name="email"
              required
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Contraseña..."
              value={this.state.password}
              onChange={this.update}
              name="password"
              required
            />
          </div>

          <div className="form-group">
            {/*  <input
              type="text"
              className="form-control"
              placeholder="Password..."
              value={this.state.roller}
              onChange={this.update}
              name="roller"
            /> */}

            <select
              className="form-control"
              placeholder="Password..."
              value={this.state.roller}
              onChange={this.update}
              name="roller"
              required
            >
              <option value="1">Gastos</option>
              <option value="2">Gastos de Viaje</option>
            </select>
          </div>
          <div className="USER">
            <button type="submit" className="btn btn-primary">
              LOGIN
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
