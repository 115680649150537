import React, { Component } from "react";
import { Route } from "react-router-dom";
import Auth from "./components/Auth";
/* import View from './components/View'; */
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

class App extends Component {
  render() {
    return (
      <div className="container">
        <Route path="/" component={Auth} />
      </div>
    );
  }
}

export default App;
